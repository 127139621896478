import Seo from "components/seo";
import Layout from "components/layout";
import { graphql } from "gatsby";
import React from "react";
import BlogTitle from 'components/BlogTitle'
import Button from 'components/Button'
import { getImage } from "gatsby-plugin-image"

import {articleNav, blogContent} from "./singleBlog.module.css"

function SingleBlog({ data, pageContext }) {
    const post = data.wpPost;
    const { seo } = post;
    return (
        <>
            <Seo title={seo.title} description={seo.metaDesc} />
            <Layout>
                <article>
                    <BlogTitle
                        image={getImage(post.featuredImage.node.localFile)}
                        altText={post.featuredImage.node.altText}
                        title={post.title}
                        authorName={post.author.node.name}
                        authorAltText={post.author.node.name}
                        authorImageURL={post.author.node.avatar.url}
                        datePublished={post.date}
                    />

                    <div className={blogContent}
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                </article>

                {(pageContext.prevArticleSlug || pageContext.prevArticleSlug) &&
                <section className={articleNav}>
                    {pageContext.prevArticleSlug && <Button to={pageContext.prevArticleSlug} inverted={true}>PREVIOUS ARTICLE</Button>}
                    {pageContext.nextArticleSlug && <Button to={pageContext.nextArticleSlug}>NEXT ARTICLE</Button>}
                </section>}
            </Layout>
        </>
    );
}

export const query = graphql`
    query SinglePostQuery($id: String) {
        wpPost(id: { eq: $id }) {
            id
            content
            slug
            date(formatString: "DD/MM/YY")
            title
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                layout: FULL_WIDTH
                            )
                        }
                        publicURL
                    }
                    altText
                }
            }
            author {
                node {
                    name
                    slug
                    avatar {
                        url
                    }
                }
            }
            seo {
                metaDesc
                title
            }
        }
    }
`;

export default SingleBlog;
